import React from 'react'
import { Component } from 'react'
import { RequestGetter } from '../../helpers/request'
import { Link, navigate, withPrefix } from 'gatsby'
import {
  RelatedImg,
  StyledRowRelated,
  StyledCol,
  RelatedTitle,
  ShowMore
} from '../StylesPages/blogStyle'

interface Blog {
  id: number
  autor: string
  content: string
  updated_at: string
  image_url: string
  order: number
  short_description: string
  title: string
  slug: string
  small_title: string
}

class RelatedArticles extends Component<{ currentArticle: string }> {
  state = {
    blogs: []
  }

  componentDidMount() {
    this.getBlogs()
  }

  getBlogs = () => {
    RequestGetter('get', 'getblogs').then((data: any) => {
      this.setState({ blogs: data.data.blogs })
    })
  }

  render() {
    let acc = -1
    const blogs = this.state.blogs
      .filter((blog: Blog) => blog.slug !== this.props.currentArticle)
      .map((blog: Blog) => {
        const truncatedDescription = blog.short_description.substring(0, 100) + '...'
        acc++
        return (
          <StyledRowRelated
            key={blog.id}
            className={`row ${acc === 0 ? 'first' : 'other'}`}
            onClick={() => navigate(`/article/${blog.slug}`)}
          >
            <StyledCol className="col-sm-12 col-md-12 col-lg-12  col-xl-5 text-center">
              <RelatedImg className="img-responsive" src={blog.image_url} />
            </StyledCol>
            <StyledCol className="col-sm-12 col-md-12 col-lg-12 col-xl-7">
              <p className="description">{truncatedDescription}</p>
            </StyledCol>
          </StyledRowRelated>
        )
      })
      .slice(0, 5)
    return (
      <div className="container">
        <RelatedTitle> Te recomendamos</RelatedTitle>
        {blogs}
        {blogs.length === 5 && this.state.blogs.length > 5 && (
          <ShowMore className="row">
            <div className="col-12">
              <Link to="/blog">Ver Todos</Link>
            </div>
          </ShowMore>
        )}
      </div>
    )
  }
}

export default RelatedArticles
