import React, { Component } from 'react'
import { Link } from 'gatsby'
import { RouteComponentProps } from '@reach/router'
import { RequestGetter } from '../../helpers/request'
import RelatedArticles from '../RelatedArticles/'
import { Image, ArticleWrapper, ArticlesSection, BreadCumb } from '../StylesPages/articleStyle'
const facebook = require('../../assets/images/general/facebook.png')
const twitter = require('../../assets/images/general/twitter.png')

interface ArticleType {
  id: number
  autor: string
  content: string
  updated_at: string
  image_url: string
  short_description: string
  title: string
  small_title: string
  getArticle: boolean
}

class Article extends Component<RouteComponentProps<{ slug: string }>, ArticleType> {
  state: ArticleType = {
    id: 0,
    autor: '',
    content: '',
    updated_at: '',
    image_url: '',
    short_description: '',
    title: '',
    small_title: '',
    getArticle: false
  }

  componentDidMount() {
    this.getArticle(this.props.slug || '')
  }

  getArticle = (articleSlug: string) => {
    RequestGetter('get', 'getblog/' + articleSlug).then((data: any) => {
      const response = data.data
      this.setState({
        id: response.id,
        autor: response.autor,
        content: response.content,
        updated_at: response.updated_at,
        image_url: response.image_url,
        short_description: response.short_description,
        title: response.title,
        small_title: response.small_title,
        getArticle: true
      })
    })
  }

  getMonthIni = (month: string) => {
    switch (month) {
      case '01':
        return 'ENE'
        break
      case '02':
        return 'FEB'
        break
      case '03':
        return 'MAR'
        break
      case '04':
        return 'ABR'
        break
      case '05':
        return 'MAY'
        break
      case '06':
        return 'JUN'
        break
      case '07':
        return 'JUL'
        break
      case '08':
        return 'AGO'
        break
      case '09':
        return 'SEP'
        break
      case '10':
        return 'OCT'
        break
      case '11':
        return 'NOV'
        break
      case '12':
        return 'DIC'
        break
    }
  }

  componentWillReceiveProps(nextProps: any) {
    if (nextProps.slug !== this.props.slug) {
      this.getArticle(nextProps.slug || '')
    }
  }

  render() {
    let truncatedDate = ''
    if (this.state.getArticle) {
      truncatedDate = this.state.updated_at.substr(0, 10)
      const splittedDate = truncatedDate.split('-')
      const month = this.getMonthIni(splittedDate[1])
      truncatedDate = month + ' - ' + splittedDate[2] + ' - ' + splittedDate[0]
    }

    return (
      <div className="container">
        <div className="row">
          <div className="col-sm-4" style={{ color: 'black', marginTop: '20px' }}>
            <BreadCumb>
              <Link className="see_more text-right" to={`/blog`} style={{ color: '#000' }}>
                BLOG
              </Link>{' '}
              &nbsp; <span> > </span> <b> &nbsp; {this.state.title} </b>
            </BreadCumb>
          </div>
        </div>
        {this.state.getArticle && (
          <div className="row" style={{ color: 'black' }}>
            <ArticleWrapper className="col-md-8">
              <Image src={this.state.image_url} />
              <p className="date">{truncatedDate}</p>
              <h1 className="title">
                {this.state.title} <br /> <span>{this.state.small_title} </span>
              </h1>
              <p className="autor">Por {this.state.autor}</p>
              <div dangerouslySetInnerHTML={{ __html: this.state.content }} />
              <br />
              <p className="share">
                Compartir:
                <a
                  href={'https://www.facebook.com/sharer/sharer.php?u=' + location.href}
                  target="_blank"
                >
                  <img src={facebook} />
                </a>
                <a
                  href={
                    'https://twitter.com/intent/tweet?&hashtags=grandetable&text=' + location.href
                  }
                  target="_blank"
                >
                  <img src={twitter} />
                </a>
              </p>
            </ArticleWrapper>
            {this.state.id > 0 && (
              <ArticlesSection className="col-md-4">
                <RelatedArticles currentArticle={this.props.slug || ''} />
              </ArticlesSection>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default Article
