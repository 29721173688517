import React from 'react'
import { withPrefix } from 'gatsby'
import { Router } from '@reach/router'
import Layout from '../components/Layout'
import Article from '../components/Article'

export default () => (
  <Layout themeType="dark" currentPage={'blog'}>
    <Router>
      <Article path={withPrefix('/article/:slug')} />
    </Router>
  </Layout>
)
